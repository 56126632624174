root {
    /* Link Button */
    --link-underline-gradient-color-left: #ffd600;
    --link-underline-gradient-color-right: #ff5722;
    --link-underline-gradient: linear-gradient(#ffd600, #ff5722);
    --link-underline-width: calc(0% - 0px);

    /* Content Heading Button */
    --heading-text-color: #1d1d1f;
    --heading-text-color-hover: #ff7043;
    --heading-subtext-color: #86868b;
    --heading-subtext-color-hover: #ff7043;

    /* Blog Post */
    --blog-text-color: #1d1d1fff;
    --blog-text-color-hover: #ff7043ff;
    --blog-background: #ffffff00;
    --blog-background-hover: #ffffffff;

    --schedule-a-demo-gradient-color1: #ffd600;
    --schedule-a-demo-gradient-color2: #ff5722;

    --nav-link-content-opacity: 0;

    font-family: 'Fira Sans', sans-serif;
}

body {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Fira Sans', sans-serif;
    margin: 0px;
}

i {
    width: 16px;
    height: 16px;
}

.icon-img {
    width: 16px;
    height: 16px;
}

a {
    text-decoration: none;
    color: #ffffff;
    transition: 0.4s;
}

body {
    width: 100%;
}

.nav {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 6;
}

.nav .container {
    width: 100%;
    max-width: 1280px;
    height: 110px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: sticky;
    top: 10px;
    margin: 0 auto;
}

.nav-logo {
    display: flex;
    padding-left: 1vh;
}

.nav-logo a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo a img {
    width: 220px;
}

.nav-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.nav-link {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    white-space: nowrap;
}

.nav-link>a {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    --link-underline-width: calc(0% - 0px);
    --nav-link-content-opacity: 0;
    transition: --link-underline-width 1s ease-out, --nav-link-content-opacity 0.3s;
}

.nav-link>a:after {
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, #ffd600, #ff5722);
    height: 3px;
    width: 0;
    right: 0;
    bottom: -8px;
    transition: 0.5s;
}

.nav-link:hover>a:after {
    width: 100%;
    left: 0;
    right: unset;
}

.nav-link:hover {
    --link-underline-width: calc(100% - 56px);
    --nav-link-content-opacity: 1;
}

.nav-link:hover a {
    font-weight: 600;
}

.nav-link:hover .nav-link-content,
.nav-link-content:hover {
    opacity: 1;
    visibility: visible;
}

.nav-link-content:hover+a {
    --link-underline-width: calc(100% - 56px);
    --nav-link-content-opacity: 1;
}

.nav-link>a i {
    display: none;
}

.nav-link a:visited {
    text-decoration: none;
}

.nav-link-content {
    position: absolute;
    width: max-content;
    top: 110px;
    display: grid;
    grid-template-areas: "left right";
    opacity: var(--nav-link-content-opacity);
    /* transition: --nav-link-content-opacity 0.3s; */
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.nav-link-content * {
    margin: 0px;
    padding: 4px 0;
    box-sizing: content-box;
}

.nav-link-content h3 {
    font-size: 12px;
    color: #1d1d1f;
    font-weight: 600;
}

.nav-link-content a {
    font-size: 14px;
    color: #6e6e73;
    font-weight: 400;
}

.nav-link-content a:hover {
    color: #ff7043;
}

.nav-link-content i {
    text-align: center;
    color: #c5c5c7;
}

.nav-link-content h6 {
    font-weight: 400;
    color: #6e6e73;
    padding-left: 4px;
}

.nav-link-content-company {
    opacity: 0;
}

.nav-link-content-resources {
    grid-template-columns: 1fr 1fr;
    opacity: 0;
}

.nav-link-content-left {
    padding: 20px 30px;
    grid-area: left;
    background-color: #fbfbfd;
    border-radius: 10px 0 0 10px;
    min-width: 208px;
}

.nav-link-content-left h3:nth-child(2) {
    padding-top: 12px !important;
}

.nav-link-content-right {
    padding: 20px 30px;
    grid-area: right;
    background-color: #f2f2f4;
    border-radius: 0 10px 10px 0px;
    min-width: 208px;
}

.nav-link-content-left ul,
.nav-link-content-right ul {
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
}

.subtext {
    font-size: 10px;
    color: #86868b;
    padding-top: 0 !important;
    padding-bottom: 4px;
}

.nav-link ul li a {
    display: grid;
    grid-template-columns: 20px 1fr;
}

.nav-link.schedule-a-demo-mobile {
    display: none;
}

.company-right-blog {
    display: flex;
}

.company-right-blog>a>i {
    float: left;
    color: #ff8f00;
    font-size: 16px;
}

.company-right-blog>a h5 {
    padding: 2px 0 0;
}

.company-right-blog>a>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-right-blog a>div h5,
.company-right-blog a>div span {
    transition: all 0.1s ease;
}

.company-right-blog a:hover>div h5 {
    color: #ff7043;
}

.company-right section {
    margin-top: 10px !important;
}

.nav-button-login {
    display: none;
}

.box-shadow-radius {
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);
    padding: 10px;
    margin-right: 8px;
    border-radius: 4px;
}

.post * {
    box-sizing: border-box;
}

.nav-link-content .post a {
    display: block;
}

.nav-link-content .post a .body {
    padding: 6px 10px;
    border-left: #ff8f00 solid 2px;
}

.nav-link-content .post a:hover .body {
    background-color: #fbfbfd;
    border-left: #ff7043 solid 2px;
}

.nav-link-content .post a:hover .desc {
    color: #ff7043;
}

.nav-link-content .post .pill {
    font-size: 10px;
    color: #ff7043;
    background-color: #ffddb2;
    display: inline-block;
    border-radius: 30px;
    padding: 4px 12px;
}

.nav-link-content .post .desc {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1f;
}

h5 {
    font-size: 14px;
    color: #6e6e73;
}

h6 {
    font-size: 12px;
    font-weight: 600;
    color: #1d1d1f;
}

h6:hover {
    color: #ff7043;
}

.nav-user {
    display: flex;
    min-width: 200px;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}

.nav-user a {
    padding: 9px;
    text-align: center;
    color: #ffffff;
}

.button-schedule-a-demo {
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    position: relative;
    transition: all 0.5s ease;
}

.button-schedule-a-demo:hover:before {
    width: 100%;
    left: 0;
    right: unset;
}

.button-schedule-a-demo>i {
    color: #ffffff;
}

img {
    max-width: 100%;
}

.resources-left-documentation {
    display: flex;
}

.resources-left-documentation>a>i {
    float: left;
    color: #ff8f00;
    font-size: 16px;
}

.resources-left-documentation>a h5 {
    padding: 0;
}

.resources-left-documentation>a>div {
    margin: 0 0 0 40px;
    padding: 0;
}

.resources-right>section,
.resources-left>section {
    display: flex;
    gap: 30px;
}

.resources-right-wrapper {
    margin-top: -4px;
    padding: 0;
}

.resources-left-documentation a>div h5,
.resources-left-documentation a>div span {
    transition: all 0.1s ease;
}

.resources-left-documentation a:hover>div h5,
.resources-left-documentation a:hover>div p {
    color: #ff7043;
}

.nav-hamburger {
    display: none;
}

.hide {
    display: none !important;
}

.nav-active {
    background: black;
}

.nav-active .button-schedule-a-demo {
    background-image: linear-gradient(90deg, #ff5722 0%, #ff8f00 50%, #ffd600 100%);
    transition: 0.5s;
}

.nav .button-schedule-a-demo:hover {
    background: #ff5722;
    background-image: linear-gradient(90deg, #ff5722 0%, #ff8f00 50%, #ffd600 100%);
    transition: 0.5s;
}

.nav-active .button-schedule-a-demo:hover {
    background: #ff5722 !important;
    transition: 0s;
}

.main-container {
    width: 100%;
    margin: 0 auto;
}

.hero-section {
    justify-content: center;
    align-items: center;
    background: linear-gradient(150deg, #ff1744 0%, #ff5722 50%, #ff8f00 100%);
    margin-top: -200px;
    padding-top: 200px;
}

.hero-section-wrapper {
    margin-top: 100px;
    background-image: url('../images/homepage/ja-bg-pattern-dark.svg');
}

.hero-section-container {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 120px;
}

.hero-left {
    padding-left: 32px;
}

.hero-right {
    padding-right: 56px;
}

.hero-section-image {
    height: 80%;
    float: right;
}

.hero-section-container h1 {
    color: white;
    font-size: 64px;
    font-weight: 800;
}

.hero-section-container p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
}

.hero-section-container .company-description {
    margin-top: 30px;
    margin-bottom: 50px;
}

.realwear-badge {
    width: 150px;
    margin-top: 20px;
}

.hero-section-container .hero-product-container { 
    display: block;
    padding: 10px 10px 10px 70px;
    color: #fff;
    border: 3px solid #000;
    border-radius: 10px;
    margin-bottom: 15px;
    background-position: 15px 13px;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}

.hero-section-container .hero-product-container .hero-product-title { 
    font-size: 24px;
    font-weight: 700;
    color: #000;
}

.hero-section-container .hero-product-container .hero-product-description { 
    color: #000;
}

.hero-section-container .hero-product-container.platform {
    background-image: url("/assets/images/homepage/icons/icon-journeyapps-black.svg");
}
.hero-section-container .hero-product-container.powersync {
    background-image: url("/assets/images/homepage/icons/icon-powersync-black.svg");
}

.partnership-section {
    justify-content: center;
    align-items: center;
    background-image: url('../images/homepage/ja-bg-pattern-dark.svg');
    background-color: black;
    background-size: cover;
    margin-top: -80px;
    text-align: center;
    padding-top: 55px;
}

.partnership-section-container {
    max-width: 1280px;
    padding-top: 50px;
    margin: 0 auto;
    padding-bottom: 100px;
}

.partnership-title {
    color: white;
    font-size: 21px;
}

.customer-logos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.partnership-gap-section {
    height: 210px;
    background-color: black;
    margin-top: -210px;
    z-index: 1;
    position: relative;
}

.black-gap-section {
    height: 220px;
    background-color: black;
}

.black-gap-section-container {
    max-width: 1280px;
    position: relative;
    margin: 0 auto;
    height: 180px;
}

.-mt-130 {
    margin-top: -110px;
}

.platform-section {
    background-color: black;
    transform: skew(0deg, -7.5deg);
    margin-top: -125px;
    z-index: 1;
    position: relative;
}

.platform-section-container {
    max-width: 1280px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 100px;
    transform: skew(0deg, 7.5deg);
}

.platform-title {
    font-size: 56px;
    font-weight: 800;
    color: white;
}

.gradient {
    background-image: linear-gradient(90deg, #ffff00 0%, #ff8f00 50%, #ff5722 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.platform-paragraph {
    font-size: 18px;
    color: white;
    padding: 25px 200px 75px;
}

.diagram-button-wrapper {
    padding-top: 14px;
}

.diagram-button {
    border-radius: 5px;
    background-color: #fbfbfd;
    border: 1px solid #c5c5c7;
    height: 85px;
    margin-bottom: 37px;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.diagram-button .diagram-button--heading {
    font-size: 24px;
    font-weight: 700;
    color: #1d1d1f;
    margin: 0;
    padding-bottom: 2px;
}

.diagram-button:hover .diagram-button--heading {
    color: #ffffff;
}

.diagram-button .diagram-button--description {
    font-size: 16px;
    color: #1d1d1f;
    margin: 0;
}

.diagram-button:hover .diagram-button--description {
    color: #ffffff;
}

.diagram-button i {
    color: #1d1d1f;
}

.diagram-button:hover {
    text-decoration: none;
}

.diagram-button:hover {
    background-color: #1d1d1f;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.5));
    padding: 40px 30px;
    z-index: 2;
}

.card-image {
    flex: 1;
    width: 50%;
    margin: auto;
}

.card-heading {
    flex: 2;
    text-align: left;
    font-size: 20px;
    line-height: 1.25;
    color: #1d1d1f;
    margin-top: 30px;
    font-weight: 800;
}

.card-paragraph {
    flex: 3;
    text-align: left;
    font-size: 16px;
    color: #1d1d1f;
    margin-top: 10px;
}

.border-gap {
    border-right: 1px solid black;
    height: 80px;
    margin: 30px 0px;
}


/**** FOOTER ****/

.footer-section {
    background-color: #000000;
    color: #ffffff;
    padding-top: 40px;
}

.footer-section-container {
    max-width: 1280px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.ja-footer .ja--margin-bottom {
    margin-bottom: 10px;
}

.ja-footer ul.ja-social-footer {
list-style: none;
padding-left: 0;
}

.ja-footer ul.ja-social-footer li {
display: inline-block;
padding-right: 15px;
font-size: 2em;
}

.ja-footer ul.ja-social-footer li:hover {
color: #ff7043;
opacity: 0.7;
transition: 0.25s ease;
}

.ja-footer {
    font-size: 14px;
    background-color: #ECEFF1;
    color: #455A64;
}

.ja-footer.ja-footer-light {
background-color: transparent !important;
color: #ffffff;
padding-bottom: 200px;
background-size: contain;
background-repeat: no-repeat;
background-position: bottom;
}

.ja-footer.ja-footer-dark {
background-color: #000 !important;
color: #ffffff !important;
padding-top: 80px;
padding-bottom: 40px;
background-size: contain;
background-repeat: no-repeat;
background-position: bottom;
}

.ja-footer__logo {
max-width: 220px;
margin-bottom: 80px;
display: block;
}

.ja-footer__link-lists--categories {
padding-bottom: 80px;
}

.ja-footer h3 {
font-size: 14px;
line-height: 1em;
font-weight: 600;
margin-bottom: 8px;
}
.ja-footer__link-list h4 {
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
    margin-bottom: 16px
}

.ja-footer a {
transition: all 0.3s ease;
}

.ja-footer a:hover {
transition: all 0.3s ease;
}

.ja-footer__link-list a {
display: block;
margin-bottom: 5px;
}

.ja-footer__link-list.ja-footer-nav a {
font-size: 14px;
margin-bottom: 10px;
}

.ja-footer__link-list a:hover{
    color: #ff7043;
}

.ja-footer-light .ja-footer__link-list a {
color: #fff;
}

.ja-footer-light .ja-footer__link-list a:hover {
color: #ff7043;
}

/* New Dark Footer */
.ja-footer-dark .ja-footer__link-list a {
color: #fff;
}

.ja-footer-dark .ja-footer__link-list a:hover {
color: #ff7043;
}

.ja-footer p {
line-height: 1.7em;
margin-bottom: 30px;
}

.ja-legal {
    font-size: 11px;
    line-height: 23px;
}
.ja-legal a {
color: #ffffff;
padding-bottom: 1px;
text-decoration: none;
}

.ja-legal a:hover {
color: #ff7043;
}

.ja-legal-links {
text-align: right;
}

.ja-footer-light .ja-legal a {
color: #fff;
border-bottom: 1px solid #fff;
}

.ja-footer-light .ja-legal a:hover {
color: #ff7043;
}

@media (max-width: 767px) {
.ja-footer p {
    margin-bottom: 48px;
}

.ja-footer__link-list--mobile {
    display: flex !important;
    justify-content: space-around;
}
div>.ja-footer__link-list {
    padding-top: 20px;
  }
}

.vl {
    border-left: 1px solid #c5c5c7;
    height: 100%;
    position: absolute;
    left: 41.666%;
    margin-left: -4px;
    top: 15px;
    z-index: 1;
}

.border-orange {
    border-right: 2px solid #ff7043;
    z-index: 2;
}

.border-light-orange {
    border-right: 2px solid #ff8d00;
    z-index: 2;
}

.border-yellow {
    border-right: 2px solid #ffd600;
    z-index: 2;
}

@media only screen and (max-width: 768px) {
    html {
        width: 100%;
        overflow-x: hidden;
    }

    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    .nav-link {
        width: 100%;
        overflow-x: hidden;
    }

    .nav.open {
        height: 100vh;
    }

    .nav .container {
        max-width: 100%;
        height: 80px;
        top: 0;
        padding-right: 0px;
    }

    .nav-logo a img {
        max-width: 240px;
    }

    .nav-hamburger {
        display: flex;
        align-items: center;
        margin-right: 25px;
    }

    .nav-hamburger i {
        color: white;
        font-size: 24px;
        margin-top: -8px;
    }

    .nav-hamburger i.fa-xmark {
        display: none;
    }

    .nav.open .nav-hamburger i.fa-xmark {
        display: inline-block;
    }

    .nav.open .nav-hamburger i.fa-bars {
        display: none;
    }

    .nav.open .nav-link-content {
        padding-left: -30px;
        padding-right: -30px;
    }

    .nav-links,
    .nav-user {
        display: none;
    }

    .nav-links {
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fbfbfd;
        top: 80px;
        height: 100vh;
        overflow: scroll;
    }

    .nav-link {
        display: block;
    }

    .nav-link-underline {
        display: none;
    }

    .nav-link a,
    .nav-link a:visited {
        color: black;
        display: block;
        font-weight: 600;
    }

    .nav-link>a:after {
        display: none;
    }

    .nav-link>a i.fa-angle-left {
        display: inline-block;
        color: #1d1d1f;
    }

    .nav-link>a i.fa-chevron-right {
        display: block;
        float: right;
        color: #1d1d1f;
        margin-top: 10px;
    }

    .nav-link.open .nav-link-content {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .nav-link .fa-angle-left {
        display: none !important;
    }

    .nav-link.open .fa-angle-left {
        display: inline-block !important;
        margin-right: 23px;
    }

    .nav-link.open .fa-chevron-right {
        display: none !important;
    }

    .nav.open .nav-links {
        display: block;
        /* padding-top: 10%; */
    }

    .nav.open .container {
        background-color: #fbfbfd;
    }

    .nav.open i.fa-xmark {
        color: #1d1d1f;
    }

    .nav-link-content {
        position: static;
        display: none;
        width: 100%;
        max-width: 100%;
        margin-left: -30px;
        margin-right: -30px;
    }

    .nav-link-content h3 {
        font-size: 14px;
    }

    .nav-link-content.open {
        display: block;
    }

    .nav-link-content>div {
        /* padding: 20px; */
        width: 100%;
        max-width: 100%;
    }

    .nav-link-content .post .image {
        height: 68px;
    }

    .nav-link-content .post .desc {
        font-size: 12px;
    }

    .nav-link.schedule-a-demo-mobile {
        display: block;
        padding: 20px 30px;
        position: absolute;
        width: 100%;
        bottom: 90px;
    }

    .nav-link.schedule-a-demo-mobile a {
        background-image: linear-gradient(to right, #fcb116 0%, #ff6f00 100%);
        box-shadow: none;
        border: none;
        border-radius: 8px;
        padding: 14px;
        color: white;
        text-align: center;
    }

    .nav-link.schedule-a-demo-mobile i {
        display: none !important;
    }

    .nav-link.open a.nav-button-solutions,
    .nav-link.open a.nav-button-company,
    .nav-link.open a.nav-button-resources {
        position: relative;
        margin-bottom: 10px;
    }

    .nav-link.open a.nav-button-solutions::after,
    .nav-link.open a.nav-button-company::after,
    .nav-link.open a.nav-button-resources::after {
        content: '';
        width: 120%;
        height: 1px;
        border-bottom: 1px solid #c5c5c7;
        display: block;
        background-image: none;
        margin-right: -30px;
        top: 36px;
    }

    .resources-left>section,
    .resources-right>section {
        display: block;
    }

    .hero-section {
        margin-top: -120px;
        background-image: linear-gradient(150deg, #ff1744 0%, #ff5722 50%, #ff8f00 100%);
        overflow-x: hidden;
        padding-bottom: 100px;
    }

    .hero-section-wrapper {
        margin-top: 20px;
        background-image: none;
    }

    .hero-section-container {
        padding: 40px 20px;
    }

    .hero-section-container .hero-left {
        text-align: center;
        padding-left: 12px;
    }

    .hero-section-container h1 {
        font-size: 32px;
    }

    .hero-section-container h6 {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
    }

    .hero-section-container .hero-right {
        padding-right: 12px;
    }

    .hero-section-image {
        max-width: 100%;
        height: auto;
    }

    .hero-section-container .schedule-demo-btn-container {
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .hero-section-container .button-schedule-demo {
        display: block;
        width: 100%;
    }

    .partnership-section {
        overflow-x: hidden;
        padding: 50px 0;
        background-image: none;
    }

    .partnership-section-container {
        padding: 50px 20px;
    }

    .partnership-gap-section {
        margin-top: -150px;
    }

    .diagram-button {
        padding: 12px 18px;
    }

    .diagram-button .diagram-button--heading {
        font-size: 21px;
    }
    
    .diagram-button .diagram-button--description {
        font-size: 14px;
    }

    .diagram-button:last-child {
        margin-bottom: -10px;
    }

    .action-title {
        line-height: 1.25;
    }

    .border-orange {
        border-right: none;
    }
    
    .border-light-orange {
        border-right: none;
    }
    
    .border-yellow {
        border-right: none;
    }

    .border-gap {
        width: 50%;
    }

    .ja-footer-nav {
        width: 50%;
    }

    .vl {
        display: none;
    }

    .ja-legal-links {
        text-align: left;
        padding-top: 40px;
    }
}